import firebase from "@/utils/firebaseInit";
import firebasePKG from "firebase";
const db = firebase.firestore();

export const addUpdateDocument = (path = "", data = {}) => {
    return new Promise(async (resolve, reject) => {
        try {

            if(!path)  {
                reject(new Error("Path is required"));
                return;
            }

            let splitPath = path.split(">");

            let isCollection = splitPath.length % 2 !== 0;

            let query = db;
            if(splitPath.length) {
                splitPath.forEach((value, index) => {
                    if(index % 2 !== 0) {
                        query = query.doc(value);
                    } else {
                        query = query.collection(value);
                    }
                })
            }

            let setData;

            if(isCollection) {
                const docId = await query.doc().id;

                query = query.doc(docId);

                setData = {
                    id: docId,
                    createdAt: firebasePKG.firestore.FieldValue.serverTimestamp(),
                    updatedAt: firebasePKG.firestore.FieldValue.serverTimestamp(),
                    ...data
                }
            } else {
                setData = {
                    updatedAt: firebasePKG.firestore.FieldValue.serverTimestamp(),
                    ...data
                }
            }

            query
            .set(setData, { merge: true })
            .then((docRef) => {
                resolve(docRef);
            })
            .catch((error) => {
                reject(error);
            })
        } catch (error) {
            reject(error);
        }
    })
}