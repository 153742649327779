<template>
  <div class="advertismentWrapper advSection">
    <b-spinner style="width: 50px; height: 50px" class="spinner"  v-if="showSpinner"/>
    <b-row>
      <!-- Create Ad -->
      <b-col md="12">
        <validation-observer ref="bannerForm">
          <form ref="form">
            <b-card>
              <b-row>
                <b-col md="4" sm="6" class="mb-0">
                  <validation-provider
                    #default="{ errors }"
                    name="title"
                    rules="required"
                  >
                    <b-form-group label="Title">
                      <b-form-input
                        placeholder="Enter title here"
                        v-model="formData.title"
                        :maxlength="25"
                        @blur="formData.title = formData.title.trim()"
                        autocomplete="off"
                        required
                      />
                      <small class="text-danger" style="display: block">{{
                        errors[0]
                      }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="4" sm="6" class="mb-0">
                  <validation-provider
                    #default="{ errors }"
                    name="banner image"
                    rules="required"
                  >
                    <b-form-group label="Upload Banner Here">
                      <b-form-file
                        placeholder="Upload image"
                        v-model="formData.bannerFile"
                        accept="image/*"
                        required
                      />
                      <small
                        v-if="bannerImageError != ''"
                        class="text-danger"
                        style="display: block"
                        >{{ bannerImageError }}</small
                      >
                      <small
                        v-else
                        class="text-danger"
                        style="display: block"
                        >{{ errors[0] }}</small
                      >
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="4" sm="6" class="mb-0">
                  <b-form-group label="Link">
                    <b-form-input
                      placeholder="Enter link here"
                      v-model="formData.link"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4" sm="6" class="mb-0">
                  <b-form-group label="Start Date">
                    <b-form-datepicker
                      placeholder="Start date"
                      v-model="formData.startDate"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4" sm="6" class="mb-0">
                  <b-form-group label="End Date">
                    <b-form-datepicker
                      placeholder="End date"
                      v-model="formData.endDate"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <div class="d-flex justify-content-end">
                    <b-button
                      variant="outline-secondary"
                      class="mr-1"
                      @click.prevent="clearData"
                      >CANCEL</b-button
                    >
                    <b-button
                      v-if="!savingData"
                      variant="primary"
                      @click.prevent="saveData"
                      >SUBMIT</b-button
                    >
                    <b-button v-else variant="primary" disabled>
                      <b-spinner small />
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </form>
        </validation-observer>
      </b-col>

      <!-- Ad List -->
      <b-col md="12">
        <b-card>
          <b-row>
            <b-col md="9" sm="7" class="my-1">
             
              <h3 class="card-title">Pricing</h3>
           
            </b-col>
            <b-col md="3" sm="5" class="my-1" v-if="filteredItems && filteredItems.length">
              <b-form-input class="search" placeholder="Search..." v-model="filter" />
            </b-col>

            <template>
              <b-col md="12" v-if="filteredItems && filteredItems.length">
                <b-table
                  hover
                  responsive
                  :per-page="perPage"
                  :current-page="currentPage"
                  :items="filteredItems"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                >
                  <template #cell(title)="data">
                    <div style="white-space: nowrap">
                      {{ data.item.title }}
                    </div>
                  </template>

                  <template #cell(banner)="data">
                    <img
                      :src="data.item.bannerUrl"
                      :alt="data.item.bannerName"
                      style="width: 58px; height: 58px; border-radius:5px"
                    />
                  </template>

                  <template #cell(link)="data">
                    <span
                      style="
                        text-decoration: underline;
                        cursor: pointer !important;
                        color: #8f00ff;
                      "
                      v-if="data.item.link"
                    >
                      {{ data.item.link }}
                    </span>
                    <span v-else>N/A</span>
                  </template>
                  <template #cell(startDate)="data">
                    <span>
                      {{ data.item.startDate || "N/A" }}
                    </span>
                  </template>
                  <template #cell(endDate)="data">
                    <span>
                      {{ data.item.endDate || "N/A" }}
                    </span>
                  </template>

                  <template #cell(status)="data">
                    <b-dropdown
                      style="
                        width: -webkit-fill-available;
                        margin-bottom: 5px;
                        text-transform: capitalize;
                      "
                      id="dropdown-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :text="data.item.status"
                      :class="data.item.status"
                    >
                      <b-dropdown-item
                        v-for="(data2, index) in advertisementStatusArray"
                        :key="index"
                        variant="data2.variant"
                        @click="openModel(data2, data)"
                        style="text-transform: capitalize"
                        >{{ data2.displayName }}</b-dropdown-item
                      >
                    </b-dropdown>
                  </template>
                </b-table>
              </b-col>
               <b-col
                v-else
                cols="12"
                style="width: 100%; text-align: center"
              >
                <p>No records found</p>
              </b-col>
                <b-col class="d-flex justify-content-between flex-wrap pt-0 paginationCol" v-if="filteredItems && filteredItems.length">
              <!-- page length -->
              <div
                class="d-flex align-items-center"
                style="white-space: nowrap"
              >
                <span class="showText">Showing 1 to</span>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="pageOptions"
                  style="margin: 0px 10px"
                />
                <span class="entiresText">of {{ filteredItems ? filteredItems.length :'' }} </span> <span class="entiresText">entries</span>
              </div>
                <!-- pagination -->
                <div>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="filteredItems ? filteredItems.length : ''"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </b-col>
            </template>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modal-changeStatus"
      ok-title="Yes"
      cancel-title="No"
      centered
      @ok="changeStatus"
      :no-close-on-backdrop="true"
      hide-header
    >
      <h3>Are you sure you want to update the status?</h3>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BSpinner,
  BCard,
  BFormDatepicker,
  BFormFile,
  BButton,
  BTable,
  BPagination,
  BFormSelect,
  BBadge,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import { getCollectionData } from "@/utils/FirebaseQueries/getQueries";
import { addUpdateDocument } from "@/utils/FirebaseQueries/addUpdateQueries";
import { uploadFile } from "@/utils/FirebaseQueries/storageQueries";
import { dbCollections, storageCollections } from "@/utils/firebaseCollections";
import { mapGetters, mapActions } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import moment from "moment";
export default {
  name: "adverisment-management",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BSpinner,
    BCard,
    BFormDatepicker,
    BFormFile,
    BButton,
    BTable,
    BPagination,
    BFormSelect,
    BBadge,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      adListSpinner: true,
      savingData: false,
      showSpinner: false,
      // TABLE DATA
      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: ['title','link','startDate','endDate','status'],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      items: [],
      fields: [
        {
          key: "title",
          label: "Title",
        },
        {
          key: "banner",
          label: "Banner",
        },
        {
          key: "link",
          label: "Link",
        },
        {
          key: "startDate",
          label: "Start Date",
        },
        {
          key: "endDate",
          label: "End Date",
        },
        {
          key: "status",
          label: "action",
        },
      ],
      bannerImageError: "",

      // FORM DATA
      formData: {
        title: "",
        bannerFile: [],
        link: "",
        startDate: "",
        endDate: "",
      },

      filter: null,
      updatedStatus: {},
    };
  },

  created() {
    var self = this;
    // self.getData();
    self.showSpinner = true;
    self.advertisementData().then(() => {
      self.showSpinner = false;
    });
  },

  methods: {
    ...mapActions({ advertisementData: "advertisement/advertisementData" }),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    saveData() {
      // debugger // eslint-disable-line
      var self = this;
      self.bannerImageError = "";
      self.$refs.bannerForm.validate().then((valid) => {
        if (valid) {
          self.savingData = true;
          if (self.formData.bannerFile.type.toLowerCase().includes("image")) {
            if (self.formData.bannerFile.size <= 5000000) {
            } else {
              self.bannerImageError = "Banner size is not more then 5mb";
              self.savingData = false;
            }
          } else {
            self.bannerImageError = "Only image is allowed";
            self.savingData = false;
          }

          if (self.bannerImageError == "") {
            try {
              uploadFile(
                `${storageCollections.BANNERS}`,
                self.formData.bannerFile.name,
                self.formData.bannerFile
              )
                .then((url) => {
                  const fileName = self.formData.bannerFile.name;
                  delete self.formData.bannerFile;
                  addUpdateDocument(`${dbCollections.ADVERTISMENTS}`, {
                    ...self.formData,
                    bannerUrl: url,
                    bannerName: fileName,
                    status: 1,
                  })
                    .then(() => {
                      self.savingData = false;
                      self.formData = {
                        title: "",
                        bannerFile: [],
                        link: "",
                        startDate: "",
                        endDate: "",
                      };
                      self.bannerImageError = "";
                      self.$refs.bannerForm.reset();
                    })
                    .catch((error) => {
                      self.savingData = false;
                      console.error("ERROR in add/update document: ", error);
                    });
                })
                .catch((error) => {
                  self.savingData = false;
                  console.error("ERROR in upload File: ", error);
                });
            } catch (error) {
              self.savingData = false;
              console.error("ERROR in create ad: ", error);
            }
          } else {
            self.savingData = false;
          }
        }
      });
    },

    clearData() {
      var self = this;

      self.savingData = false;
      self.formData = {
        title: "",
        bannerFile: [],
        link: "",
        startDate: null,
        endDate: null,
      };
    },

    openModel(data2, data) {
      var self = this;
      self.updatedStatus = {
        data2: data2,
        data: data,
      };
      self.$bvModal.show(`modal-changeStatus`);
    },

    changeStatus() {
      var self = this;
      self.showSpinner = true;
      console.log("this.updatedStatus", self.updatedStatus, self.filteredItems);
      self.filteredItems[self.updatedStatus.data.index].status =
        self.updatedStatus.data2.displayName;
      try {
        let updateObject = {
          status: self.updatedStatus.data2.key,
        };
        updateFirebase
          .updateRootCollectionDataByDocId(
            dbCollections.ADVERTISMENTS,
            self.filteredItems[self.updatedStatus.data.index].id,
            updateObject,
            (res) => {
              self.$toast.success("Status updated successfully");
              self.showSpinner = false;
            }
          )
          .catch((error) => {
            console.log(error, "error in update collection query error");
            self.showSpinner = false;
          });
      } catch (error) {
        self.showSpinner = false;
        console.log("ERROR in update status in advertisement collection");
      }
    },
  },
  computed: {
    ...mapGetters({
      getAdvertisementArray: "advertisement/getAdvertisement",
      advertisementStatusArray: "admin/advertisementStatus",
    }),
    filteredItems() {
      // debugger // eslint-disable-line
      var self = this;
      
      let arr = [];
      if (self.getAdvertisementArray && self.getAdvertisementArray.length > 0 && self.advertisementStatusArray && self.advertisementStatusArray.length > 0) {
        // console.log("advertisementStatusArray", self.advertisementStatusArray);
        // self.showSpinner = true;
        self.getAdvertisementArray.forEach((data) => {
          arr.push({
            bannerName: data.bannerName,
            bannerUrl: data.bannerUrl,
            createdAt: data.createdAt,
            endDate: data.endDate ? moment(new Date(data.endDate)).format('MM/DD/YYYY') : 'N/A',
            startDate: data.startDate ? moment(new Date(data.startDate)).format('MM/DD/YYYY') : 'N/A',
            id: data.id,
            link: data.link,
            status: data.status
              ? self.advertisementStatusArray[data.status - 1].displayName
              : "active",
            title: data.title,
            updatedAt: data.updatedAt,
          });
        });
        self.showSpinner = false;
        return arr;
      }else{
        // self.showSpinner = false;
        return [];
      }
    },
  },
};
</script>

<style>
.btn {
  text-transform: capitalize;
}
.spinner {
  z-index: 999;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
</style>