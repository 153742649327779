import firebase from "@/utils/firebaseInit";
import firebasePKG from "firebase";
const storageRef = firebase.storage().ref();

export const uploadFile = (path = "", name = "", file = {}, progress = 0, metaData = {}) => {
    return new Promise((resolve, reject) => {
        try {
            var uploadTask = storageRef.child(`${path}/${name}`).put(file, metaData);

            uploadTask.on(firebasePKG.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            },
            (error) => {
                reject(error);
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                //   console.log('File available at', downloadURL);
                    resolve(downloadURL);
                });
            })
        } catch (error) {
            reject({ error});
        }
    })
};

export const removeFile = (path = "", name = "" ) => {
    return new Promise((resolve, reject) => {
        try {
            var removeTask = storageRef.child(`${path}/${name}`);

            removeTask.delete()
            .then(() => {
                resolve(true);
            })
            .catch((error) => {
                reject(error);
            })
        } catch (error) {
            reject(error);
        }
    })
};