import firebase from "@/utils/firebaseInit.js"
const db = firebase.firestore()


/******* QUERY FOR UPDATE DATA FROM FIRESTORE ROOT COLLECTION WITH DOCUMENT ID *******************/
export const updateRootCollectionDataByDocId = async (collectionName, id, object, cb) => {
  db.collection(collectionName).doc(id).update(object)
  .then(() => {
    cb(true);
    console.log("Document successfully updated!");
    })
    .catch((error) => {
      cb(false);
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    });
 
}

/******* QUERY FOR UPDATE DATA FROM FIRESTORE ROOT SUBCOLLECTION [JYOTI] *******************/
export const updateRootSubCollectionData = async (collectionName, id, SubcollectionName, object, cb) => {
  const data = await db.collection(collectionName).doc(id).collection(SubcollectionName).get().then(function(querySnapshot) {
    querySnapshot.forEach(function(doc) {
        doc.ref.update(object);
    });
    cb(data);
  });
}

/******* QUERY FOR UPDATE DATA FROM FIRESTORE ROOT SUBCOLLECTION WITH DOCUMENT ID[JYOTI] *******************/
export const updateRootSubCollectionDataWithDocId = async (collectionName, id, SubcollectionName, subid, object, cb) => {
  const data = await db.collection(collectionName).doc(id).collection(SubcollectionName).doc(subid).update(object);
    cb(data);
}

